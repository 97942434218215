import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import './Login.css'
const apiUrl = process.env.REACT_APP_API_URL;


const Login = ({onLogin}) => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ errorEmail, setErrorEmail ] = useState(null);
    const [ errorPassword, setErrorPassword ] = useState(null);
    const [ error, setError ] =  useState(null);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const isRememberMe = localStorage.getItem('rememberMe');
        if(isRememberMe){
            setRememberMe(true)
        }
    }, [])
    
        
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setErrorEmail(null);
      };
    
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setErrorPassword(null);
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
        if(rememberMe){
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('token');
        }
      };

    const handleSignIn = async () => {

        if(!email) {
            setErrorEmail('Email is required.');
            return;
        }
      
        if(!password) {
            setErrorPassword('Password is required.');
            return;
          }
      
        try {
          const response = await axios.post(`${apiUrl}/api/user/admin-login`, {
            email: email,
            password: password,
          });
          if (rememberMe) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('rememberMe', true);
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('rememberMe');
          }

          onLogin(true)
          navigate('/dashboard')

        } catch (error) {
          console.error('API Error:', error.response.data.msg);
          setError(error.response.data.msg)
        }
      };

     return(<>
        <div className='login-page'>
            <img className='login-logo' src="assest/logo.svg"/>
            <div className='login-container'>
                <div className='welcome-container'>
                    <p className='welcome'>Hi, Welcome Back!</p>
                    <p className='msg'>Sign in to your account to continue</p>
                </div>
                <div className='login-field-container'>
                    {errorEmail ? <p className="login-label error-message">{errorEmail}</p> 
                            : 
                            <label htmlFor="emailaddress" className='login-label'>Email</label>}
                       
                       <div className="input-container">
                           <i className="icon-envelope" style={{left:"10px"}}>
                               <img src="assest/mail_icon.svg" className='login-icon'/>
                           </i>
                           <input 
                                type="email" 
                                className='login-input' 
                                id="emailaddress" required="" 
                                placeholder="name@gmail.com"
                                value={email}
                                onChange={handleEmailChange}
                                />
                       </div>
                       
                    {errorPassword ? <p className="login-label error-message">{errorPassword}</p> 
                               :
                               <label htmlFor="password" className='login-label'>Password</label>}
                       
                       <div className="input-container">
                           <i className="icon-envelope" style={{left:"10px"}}>
                               <img src="assest/password_icon.svg" className='login-icon'/>
                           </i>
                           <input 
                                type="password" 
                                className='login-input' 
                                id="password" 
                                required="" 
                                placeholder="Min. 8 character"
                                value={password}
                                onChange={handlePasswordChange}
                                />
                           <i className="icon-envelope" style={{right:"10px"}}>
                               <img src="assest/eye_icon.svg" className='login-icon'/>
                           </i>
                       </div>

                       <div className="input-container">
                                <input type="checkbox" 
                                       id="rememberMe" 
                                       className='login-input-check'
                                       checked={rememberMe}
                                       onChange={handleRememberMeChange}
                                       />
                                <label htmlFor="rememberMe" className='login-label'>Remember me</label>
                                <a href="#" className="forget-password">Forget Password?</a>
                        </div>
                        {error && <p className='login-label error-message'>{error}</p>}
                </div>
                <button className='button' onClick={handleSignIn}>
                    Sign in
                </button>
            </div>
        </div>
     </>)
}

export default Login